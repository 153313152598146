import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Select,
  OutlinedInput,
  Box,
  Chip,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import useTitle from "hooks/useTitle";
import LoadingScreen from "components/LoadingScreen";
import ImageUploader from "components/crud/ImageUploader";
import useAuth from "hooks/useAuth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

const Form = () => {
  const [routDefaultLabel, setRoutDefaultLabel] = useState("");
  const [item, setItem] = useState({});

  const setupInputs = () => {
    switch (routeDefault) {
      case "appointments":
        setRoutDefaultLabel("Evento");
        return [
          {
            name: "name",
            label: "Nome do Cliente",
            type: "input",
            disabled: true,
          },
          {
            name: "phone",
            label: "WhatsApp",
            type: "input",
            disabled: true,
          },
          {
            name: "date",
            label: "Data",
            type: "text",
            disabled: true,
          },
          {
            name: "service_id",
            label: "Serviço",
            type: "select_unique",
            options: [],
            searchTerm: "services",
          },
          {
            name: "user_id",
            label: "Profissional",
            type: "select_unique",
            options: [],
            searchTerm: "users",
          },
          {
            label: "Salvar",
            type: "button",
            variant: "contained",
            color: "primary",
            typeButton: "submit",
            context: "create",
          },
          {
            label: "Chamar no WhatsApp",
            type: "button-whatsapp",
            variant: "contained",
            color: "primary",
            typeButton: "button",
            context: "edit",
          },
          {
            label: "Cancelar Agendamento",
            type: "button-cancel-appointment",
            variant: "contained",
            color: "error",
            typeButton: "button",
            context: "edit",
          }
        ];
      case "services":
        setRoutDefaultLabel("Serviço");
        return [
          {
            type: "image",
            routeDefault: "service",
          },
          {
            name: "name",
            label: "Nome",
            type: "input",
          },
          {
            name: "price",
            label: "Preço R$",
            type: "number",
          },
          {
            name: "active",
            label: "Ativo",
            type: "checkbox",
          },
          {
            name: "show_price",
            label: "Mostrar preço para o cliente",
            type: "checkbox",
            defaultValue: true
          },
          {
            name: "user_ids",
            label: "Profissional",
            type: "select",
            options: [],
            searchTerm: "users",
          },
          {
            name: "config_id",
            label: "Configuração de horários",
            type: "link_config_id",
          },
          {
            label: "Salvar",
            type: "button",
            variant: "contained",
            color: "primary",
            typeButton: "submit",
            context: "create/edit",
          },
        ];
      case "configs":
        setRoutDefaultLabel("Configurção de horário do Serviço");
        return [
          {
            name: "service_name",
            label: "Serviço",
            type: "text",
            disabled: true,
          },
          {
            name: "duration",
            label: "Duração do serviço (em minutos)",
            type: "number",
          },
          {
            name: "duration_days",
            label: "Quantos dias fica aberto a agenda (em dias)",
            type: "number",
          },
          {
            name: "mon",
            label: "Segunda-feira",
            type: "checkbox",
          },
          {
            name: "initial_mon",
            label: "Horário de início",
            type: "number",
          },
          {
            name: "final_mon",
            label: "Horário de fim",
            type: "number",
          },
          {
            name: "tue",
            label: "Terça-feira",
            type: "checkbox",
          },
          {
            name: "initial_tue",
            label: "Horário de início",
            type: "number",
          },
          {
            name: "final_tue",
            label: "Horário de fim",
            type: "number",
          },
          {
            name: "wed",
            label: "Quarta-feira",
            type: "checkbox",
          },
          {
            name: "initial_wed",
            label: "Horário de início",
            type: "number",
          },
          {
            name: "final_wed",
            label: "Horário de fim",
            type: "number",
          },
          {
            name: "thu",
            label: "Quinta-feira",
            type: "checkbox",
          },
          {
            name: "initial_thu",
            label: "Horário de início",
            type: "number",
          },
          {
            name: "final_thu",
            label: "Horário de fim",
            type: "number",
          },
          {
            name: "fri",
            label: "Sexta-feira",
            type: "checkbox",
          },
          {
            name: "initial_fri",
            label: "Horário de início",
            type: "number",
          },
          {
            name: "final_fri",
            label: "Horário de fim",
            type: "number",
          },
          {
            name: "sat",
            label: "Sábado",
            type: "checkbox",
          },
          {
            name: "initial_sat",
            label: "Horário de início",
            type: "number",
          },
          {
            name: "final_sat",
            label: "Horário de fim",
            type: "number",
          },
          {
            name: "sun",
            label: "Domingo",
            type: "checkbox",
          },
          {
            name: "initial_sun",
            label: "Horário de início",
            type: "number",
          },
          {
            name: "final_sun",
            label: "Horário de fim",
            type: "number",
          },
          {
            label: "Salvar",
            type: "button",
            variant: "contained",
            color: "primary",
            typeButton: "submit",
            context: "create/edit",
          },
        ];
      case "users":
        setRoutDefaultLabel("Funcionário");
        return [
          {
            type: "image",
            routeDefault: "user",
          },
          {
            name: "name",
            label: "Nome",
            type: "input",
          },
          {
            name: "email",
            label: "Email",
            type: "input",
          },
          {
            name: "phone",
            label: "Celular (XX) 99999-9999",
            type: "input",
          },
          {
            name: "password",
            label: "Senha",
            type: "input",
          },
          {
            name: "active",
            label: "Ativo",
            type: "checkbox",
          },
          {
            name: "service_ids",
            label: "Serviços",
            type: "select",
            options: [],
            searchTerm: "services",
          },
          {
            label: "Salvar",
            type: "button",
            variant: "contained",
            color: "primary",
            typeButton: "submit",
            context: "create/edit",
          },
        ];
    }
  };

  const [loading, setLoading] = useState(false);
  const [stateUpdate, setStateUpdate] = useState(false);
  const { axios, user } = useAuth();
  const { id, routeDefault, view } = useParams(); // Recupera o ID da rota
  const location = useLocation();
  const [inputs, setInputs] = useState(setupInputs);

  const [type, setType] = useState("edit");

  let navigate = useNavigate();

  const setupTitle = () => {
    switch (type) {
      case "edit":
        return `Editar`;
      case "view":
        return `Visualizar`;
      default:
        return `Adicionar`;
    }
  };
  const routeConfig = () => {
    switch (type) {
      case "edit":
        return `Editar ${routDefaultLabel}`;
      case "view":
        return `Visualizar ${routDefaultLabel}`;
      default:
        return `Adicionar ${routDefaultLabel}`;
    }
  };

  useTitle(setupTitle);

  const handleChange = (e, labelName, inputType = "select") => {
    const { name, value, checked, type } = e.target;

    if (inputType == "select_unique") {
      setItem({ ...item, [labelName]: value });
      return;
    }

    if (type === "checkbox") {
      setItem({ ...item, [labelName]: checked });
      return;
    }
    if (name) {
      setItem({ ...item, [name]: value });
    } else {
      setItem({
        ...item,
        optionsValues: item.optionsValues
          ? item.optionsValues.find((i) => i.name === labelName)
            ? item.optionsValues.map((options) => {
                if (options.name === labelName) {
                  return { name: labelName, value: value };
                }
                return options;
              })
            : [...item.optionsValues, { name: labelName, value: value }]
          : [],
      });
    }
  };

  const processPayload = () => {
    const payload = { ...item, company_id: user.company_id };
    if (item.optionsValues) {
      item.optionsValues.forEach((i) => {
        payload[i.name] = i.value;
      });
    }

    return payload;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (id && Number.isInteger(parseInt(id))) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/companies/${user.company_id}/${routeDefault}/${id}`,
          {
            [routeDefault.slice(0, -1)]: {
              ...processPayload(),
            },
          }
        );
        toast.success("Atualizado com sucesso!");
        let prev = null;
        if (routeDefault === "configs") {
          prev = `services`;
        }
        navigate(`/dashboard/${prev || routeDefault}`);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/companies/${user.company_id}/${routeDefault}`,
          {
            [routeDefault.slice(0, -1)]: {
              ...processPayload(),
            },
          }
        );
        toast.success("Adicionado com sucesso!");
        navigate(`/dashboard/${routeDefault}`);
      } catch (error) {
        if(typeof error === "string") {
          toast.error(error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const show = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/companies/${user.company_id}/${routeDefault}/${id}`
      );
      setItem({
        ...response.data,
        optionsValues: inputs
          .filter((input) => input.type === "select")
          .map((input) => {
            return { name: input.name, value: response.data[input.name] };
          }),
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const listOptions = async (input) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/companies/${user.company_id}/${input.searchTerm}`
      );
      setInputs(
        inputs.map((i) => {
          if (i.name === input.name) {
            return {
              ...i,
              options: response.data.map((item) => {
                return { label: item.name, value: item.id };
              }),
            };
          }
          return i;
        })
      );
    } catch (error) {
    } finally {
      setLoading(false);
      setStateUpdate(true);
    }
  };

  const searchOptionList = () => {
    inputs.forEach((input) => {
      if ("select" === input.type || "select_unique" === input.type) {
        if (type === "create") {
          setItem({ ...item, optionsValues: [] });
        } else {
          setItem({ ...item });
        }
        listOptions(input);
      }
    });
  };

  useEffect(() => {
    if (id && Number.isInteger(parseInt(id))) {
      if (view) {
        setType("view");
      } else {
        setType("edit");
      }
      show();
    } else {
      setType("create");
    }
  }, []);

  useEffect(() => {
    if (!stateUpdate) {
      searchOptionList();
    }
  }, [inputs]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setItem({ ...item, date: searchParams.get("date") });
  }, [location.search]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      {type === "edit" &&
        inputs.map((input) => {
          switch (input.type) {
            case "image":
              return (
                <ImageUploader
                  initialImage={item["img_url"]}
                  apiUrl={`${process.env.REACT_APP_API_URL}/companies/${user.company_id}/${routeDefault}/${id}`}
                  routeDefault={input.routeDefault}
                />
              );
          }
        })}
      {type !== "view" && item && (
        <form onSubmit={handleSubmit}>
          <FlexBox flexDirection="column" sx={{ mb: 10, pb: 5 }}>
            {inputs.map((input) => {
              switch (input.type) {
                case "input":
                  return (
                    <TextField
                      label={input.label}
                      variant="outlined"
                      id={input.name}
                      name={input.name}
                      value={item[input.name] || ""}
                      onChange={(e) => handleChange(e, input.name)}
                      sx={{ maxWidth: "500px" }}
                      margin="normal"
                      placeholder={input.label}
                      disabled={type !== "create" && (input.disabled || false)}
                    />
                  );
                case "text":
                  return (
                    <TextField
                      label={input.label}
                      variant="outlined"
                      id={input.name}
                      name={input.name}
                      value={item[input.name] || ""}
                      onChange={(e) => handleChange(e, input.name)}
                      sx={{ maxWidth: "500px" }}
                      margin="normal"
                      placeholder={input.label}
                      disabled={true}
                    />
                  );
                case "number":
                  return (
                    <TextField
                      label={input.label}
                      variant="outlined"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id={input.name}
                      name={input.name}
                      value={parseFloat(item[input.name]) || ""}
                      onChange={(e) => handleChange(e, input.name)}
                      sx={{ maxWidth: "500px" }}
                      margin="normal"
                      placeholder={input.label}
                      disabled={type !== "create" && (input.disabled || false)}
                    />
                  );
                case "link_config_id": {
                  return type === "edit" && 
                     (<Link
                      sx={{ mt: 3 }}
                      href={`/dashboard/configs/${item[input.name]}`}
                    >
                      {input.label}
                    </Link>)
                  
                  
                }
                case "checkbox":
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item[input.name]}
                          onChange={(e) => handleChange(e, input.name)}
                          color="primary"
                          defaultChecked={input.defaultValue}
                        />
                      }
                      label={input.label}
                    />
                  );
                case "button":
                  return (
                    (input.context === "create/edit" ||
                      (input.context === "create" && type === "create") ||
                      (input.context === "edit" && type === "edit")) && (
                      <Button
                        variant={input.variant}
                        color={input.color}
                        sx={{ width: "fit-content", mt: 3 }}
                        type={input.typeButton}
                        onClick={input.onClick}
                      >
                        {input.label}
                      </Button>
                    )
                  );
                case "button-cancel-appointment":
                  return (
                    (input.context === "create/edit" ||
                      (input.context === "create" && type === "create") ||
                      (input.context === "edit" && type === "edit")) && (
                      <Button
                        variant={input.variant}
                        color={input.color}
                        sx={{ width: "fit-content", mt: 3, color: 'white' }}
                        type={input.typeButton}
                        onClick={async () => {
                          try {
                            const response = await axios.put(
                              `${process.env.REACT_APP_API_URL}/appointments/cancel/${item.uuid}`,
                              {}
                            );
                            toast.success("Cancelado com sucesso!");
                            navigate(`/dashboard/${routeDefault}`);
                          } catch (error) {
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        {input.label}
                      </Button>
                    )
                  );
                case "button-whatsapp":
                  return (
                    (input.context === "create/edit" ||
                      (input.context === "create" && type === "create") ||
                      (input.context === "edit" && type === "edit")) && (
                      <Button
                        variant={input.variant}
                        color='success'
                        sx={{ width: "fit-content", mt: 3, color: 'white' }}
                        type={input.typeButton}
                        onClick={() => {
                          window.open(`https://wa.me/${item.phone}`, '_blank');
                        }}
                      >
                        {input.label}
                      </Button>
                    )
                  );

                case "select":
                  return (
                    <div>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        {input.label}
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id={input.name}
                        multiple
                        value={
                          item.optionsValues?.find(
                            (option) => option.name === input.name
                          )?.value || []
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        onChange={(e) => handleChange(e, input.name)}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  input.options.find(
                                    (item) => item.value === value
                                  )?.label
                                }
                                style={{
                                  fontWeight: "bold",
                                }}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {input.options &&
                          input.options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  );
                case "select_unique":
                  return (
                    <div>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        {input.label}
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id={input.name}
                        placeholder="Teste"
                        value={item[input.name] ? [item[input.name]] : []}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        onChange={(e) =>
                          handleChange(e, input.name, "select_unique")
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={
                                  input.options.find(
                                    (item) => item.value === value
                                  )?.label
                                }
                                style={{
                                  fontWeight: "bold",
                                }}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {input.options &&
                          input.options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  );
              }
            })}
          </FlexBox>
        </form>
      )}

      {type === "view" && item && (
        <div>
          {inputs.map((input) => {
            return (
              <Typography>
                {input.label}: {item[input.name] || ""}
              </Typography>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Form;
