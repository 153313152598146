import {
  AppBar,
  Box,
  Button,
  styled,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { H2, Small } from "components/Typography";
import { TitleContext } from "contexts/TitleContext";
import useAuth from "hooks/useAuth";
import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import ProfilePopover from "./popovers/ProfilePopover";
import toast from "react-hot-toast";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// root component interface
interface DashboardNavBarProps {
  setShowMobileSideBar: () => void;
}

// custom styled components
const DashboardNavbarRoot = styled(AppBar)(() => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backgroundColor: "#ffffff",
}));

const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));

const ToggleIcon = styled(Box)(({ theme }) => ({
  width: 25,
  height: 3,
  margin: "5px",
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.main,
}));

// root component
const DashboardNavbar: FC<DashboardNavBarProps> = ({
  setShowMobileSideBar,
}) => {
  const { user } = useAuth();
  const { title } = useContext(TitleContext);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  if (downMd) {
    return (
      <DashboardNavbarRoot
        style={{ backgroundColor: "#ffffff" }}
        position="sticky"
      >
        <StyledToolBar>
          <Box flexGrow={1} textAlign="start">
            <H2 lineHeight={0} mx={1} fontWeight="bold" color="text.primary">
              {title}
            </H2>
          </Box>

          <Button
          variant="text"
          color="primary"
          sx={{ml: 5, color: 'primary.main'}}
          onClick={() => {
            navigator.clipboard.writeText(`https://agenda7.com.br/agendar/${user?.company_slug}`).then(() => {
              toast.success('Copiado com sucesso!')
            }).catch(err => {
              console.error('Failed to copy: ', err);
            });
          }}
        >
           <ContentCopyIcon sx={{ mr: 1 }}></ContentCopyIcon>
          Copiar link
        </Button>

          <ProfilePopover />
        </StyledToolBar>
      </DashboardNavbarRoot>
    );
  }
  if (downSm) {
    return (
      <DashboardNavbarRoot
        style={{ backgroundColor: "#ffffff" }}
        position="sticky"
      >
        <StyledToolBar>
          <Box flexGrow={1} textAlign="start">
            <H2 lineHeight={0} mx={1} fontWeight="bold" color="text.primary">
              {title}
            </H2>
          </Box>

          <ProfilePopover />
        </StyledToolBar>
      </DashboardNavbarRoot>
    );
  }

  return (
    <DashboardNavbarRoot position="sticky">
      <StyledToolBar>
        <H2 lineHeight={0} mx={1} fontWeight="bold" color="text.primary">
          {title}
        </H2>


        <Button
          variant="text"
          color="primary"
          sx={{ml: 5, color: 'primary.main'}}
          onClick={() => {
            navigator.clipboard.writeText(`https://agenda7.com.br/agendar/${user?.company_slug}`).then(() => {
              toast.success('Copiado com sucesso!')
            }).catch(err => {
              console.error('Failed to copy: ', err);
            });
          }}
        >
           <ContentCopyIcon sx={{ mr: 1 }}></ContentCopyIcon>
          Copiar link
        </Button>
     

        <Box flexGrow={1} ml={1} />
        <ProfilePopover />
      </StyledToolBar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
