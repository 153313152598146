import { Box, Theme, styled, useMediaQuery } from "@mui/material";
import BottomNav from "components/BottomNavigation";
import { FC, Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSideBar";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  width: `calc(100% - 80px)`,
  maxWidth: 1200,
  margin: "auto",
  paddingLeft: 200,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    overflowX: 'hidden',
    marginLeft: 0,
    padding: 5,
  },
}));


const DashboardLayout: FC = ({ children }) => {
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Fragment>
      <DashboardSidebar
        showMobileSideBar={showMobileSideBar}
        closeMobileSideBar={() => setShowMobileSideBar(false)}
      />
      <Wrapper sx={{mb: 50}}>
        <DashboardNavbar
          setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
        />
        {children || <Outlet />}
        
      </Wrapper>
      {downMd && (
        <BottomNav />
      )}
    </Fragment>
  );
};

export default DashboardLayout;
