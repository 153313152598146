import LoadingScreen from "components/LoadingScreen";
import jwtDecode from "jwt-decode";
import { createContext, ReactNode, useEffect, useReducer } from "react";
import axios from "utils/axios";

// All types
// =============================================
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export type AuthUser = null | Record<string, any>;

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

enum Types {
  Init = "INIT",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
}

type JWTAuthPayload = {
  [Types.Init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Login]: { user: AuthUser };
  [Types.Register]: { user: AuthUser };
};

type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];
// ================================================

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const isValidToken = (expiry: any) => {
  if (!expiry) return false;

  const currentTime = Date.now() / 1000;
  return expiry > currentTime;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};
const setExpiry = (expiry: string | null) => {
  if (expiry) {
    localStorage.setItem("expiry", expiry);
  }
};

const reducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    }
    case "REGISTER": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: (email: string, password: string) => Promise.resolve(),
  logout: () => { },
  register: (email: string, password: string, name: string) =>
    Promise.resolve(),
  forgetPassword: (email: string) =>
    Promise.resolve(),
  resetPassword: (password: string, password_confirmation: string) =>
    Promise.resolve(),
  updatePassword: (password: string, password_confirmation: string) =>
    Promise.resolve(),
  updateProfile: (name: string) =>
    Promise.resolve(),
  updateAvatar: (file: any) =>
    Promise.resolve(),
  getProfile: () =>
    Promise.resolve(),
  axios: axios,
});

// props type
type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email: string, password: string) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/sign_in`, {
      email,
      password,
    });


    //@ts-ignore
    const authorization = response.headers.authorization.substring(7)
    const expiry = response.headers.expiry


    setSession(authorization);
    setExpiry(expiry);

    const response_profile = await axios.get(`${process.env.REACT_APP_API_URL}/profile`);
    //@ts-ignore
    const { user } = response_profile.data;

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const register = async (
    email: string,
    name: string,
    password: string
  ) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth`, {
      email,
      name,
      password,
    });
  };

  const forgetPassword = async (
    email: string
  ) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/password`, {
      email,
      redirect_url: `${process.env.REACT_APP_URL}/reset-password/`
    });
  };

  const resetPassword = async (
    password: string,
    password_confirmation: string
  ) => {
    const queryParameters = new URLSearchParams(window.location.search)
    axios.defaults.headers.put['client'] = queryParameters.get("client") || ""
    axios.defaults.headers.put['uid'] = queryParameters.get("uid") || ""
    axios.defaults.headers.put['access-token'] = queryParameters.get("access-token") || ""

    const response = await axios.put(`${process.env.REACT_APP_API_URL}/auth/password`, {
      password,
      password_confirmation
    });
  };

  const updatePassword = async (
    password: string,
    password_confirmation: string
  ) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/auth/password`, {
      password,
      password_confirmation
    });
    
    //@ts-ignore
    const { user } = response?.data || null;

    dispatch({
      type: Types.Init,
      payload: {
        user,
        isAuthenticated: true,
      },
    });
  };
  
  const updateProfile = async (
    name: string
  ) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/profile`, {
      name
    });
  };

  const updateAvatar = async (
    file: any
  ) => {
    const formData = new FormData();
    formData.append("user[avatar]", file);
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/profile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const getProfile = async () => {
    const response_profile = await axios.get(`${process.env.REACT_APP_API_URL}/profile`);
    //@ts-ignore
    const { user } = response_profile.data;

    dispatch({
      type: Types.Init,
      payload: {
        user,
        isAuthenticated: true,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {
          setSession(accessToken);

          const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`);
          //@ts-ignore
          const { user } = response.data;

          dispatch({
            type: Types.Init,
            payload: {
              user,
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        forgetPassword,
        resetPassword,
        updatePassword,
        updateProfile,
        updateAvatar,
        getProfile,
        axios
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
