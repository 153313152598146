import Icons from "icons/sidebar";

const index = [
  {
    title: "Dashboard",
    Icon: Icons.DashboardIcon,
    path: "/dashboard"
  },
  {
    title: "Agenda",
    Icon: Icons.EventAvailableIcon,
    path: "/dashboard/appointments"
  },
  {
    title: "Histórico",
    Icon: Icons.ListIcon,
    path: "/dashboard/history"
  },
  {
    title: "Serviços",
    Icon: Icons.BusinessCenterIcon,
    path: "/dashboard/services"
  },
  {
    title: "Profissionais",
    Icon: Icons.PeopleAltIcon,
    path: "/dashboard/users"
  },
];

export default index;
