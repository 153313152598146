import {
  Box,
  Drawer,
  List,
  ListItemButton,
  styled,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { H5 } from "components/Typography";
import { useLocation } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScrollBar from "simplebar-react";
import topMenuList from "./topMenuList";
import useAuth from "hooks/useAuth";

// root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  left: 0,
  width: 200,
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "left 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: { left: -80 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: theme.palette.text.primary,
  },
}));

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "1rem",
  justifyContent: "start",
  "&:hover": { backgroundColor: "transparent" },
}));

// root component
const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = useState("");
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { user } = useAuth();

  const handleActiveMainMenu = (menuItem: any) => () => {
    setActive(menuItem.title);

    navigate(menuItem.path);
    closeMobileSideBar();
  };

  useEffect(() => {
    switch (true) {
      case location.pathname.includes("/dashboard/services"):
        setActive("Serviços");
        break;
      case location.pathname.includes("/dashboard/history"):
        setActive("Histórico");
        break;
      case location.pathname.includes("/dashboard/appointments"):
        setActive("Agenda");
        break;
      case location.pathname.includes("/dashboard/users"):
        setActive("Profissionais");
        break;
      case location.pathname.includes("/dashboard/user-profile"):
        setActive("Minha conta");
        break;
      default:
        setActive("Dashboard");
        break;
    }
  }, [location.pathname]);

  // main menus content
  const mainSideBarContent = (
    <List sx={{ height: "100%" }}>
      <StyledListItemButton disableRipple>
        <img
          src={`${process.env.REACT_APP_API_URL}${user?.company_img_url}`}
          style={{ width: "100%", textAlign: "center" }}
          width="100%"
          alt="Agenda 7"
        />
      </StyledListItemButton>

      <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
        {topMenuList.map((nav, index) =>
          user?.kind === "owner"
            ? [
                "Dashboard",
                "Agenda",
                "Histórico",
                "Serviços",
                "Profissionais",
              ].includes(nav.title) && (
                <Tooltip title={nav.title} placement="right" key={index}>
                  <StyledListItemButton
                    disableRipple
                    onClick={handleActiveMainMenu(nav)}
                  >
                    <nav.Icon
                      sx={{
                        color:
                          active === nav.title
                            ? "secondary.200"
                            : "secondary.400",
                      }}
                    />
                    <H5
                      sx={{
                        color:
                          active === nav.title
                            ? "secondary.200"
                            : "secondary.400",
                      }}
                      ml={2}
                    >
                      {nav.title}
                    </H5>
                  </StyledListItemButton>
                </Tooltip>
              )
            : user?.kind === "employee" &&
              ["Agenda", "Histórico"].includes(nav.title) && (
                <Tooltip title={nav.title} placement="right" key={index}>
                  <StyledListItemButton
                    disableRipple
                    onClick={handleActiveMainMenu(nav)}
                  >
                    <nav.Icon
                      sx={{
                        color:
                          active === nav.title
                            ? "secondary.200"
                            : "secondary.400",
                      }}
                    />
                    <H5
                      sx={{
                        color:
                          active === nav.title
                            ? "secondary.200"
                            : "secondary.400",
                      }}
                      ml={2}
                    >
                      {nav.title}
                    </H5>
                  </StyledListItemButton>
                </Tooltip>
              )
        )}
      </ScrollBar>
    </List>
  );

  // for mobile device
  if (downMd) {
    return (
      <Drawer
        anchor="left"
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{ sx: { width: 200 } }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            width: "inherit",
            position: "fixed",
            overflow: "hidden",
            flexDirection: "column",
            boxShadow: (theme) => theme.shadows[1],
            backgroundColor: (theme) => theme.palette.background.paper,
            "& .simplebar-track.simplebar-vertical": { width: 7 },
            "& .simplebar-scrollbar:before": {
              background: (theme) => theme.palette.text.primary,
            },
          }}
        >
          {mainSideBarContent}
        </Box>
      </Drawer>
    );
  }

  return <MainMenu>{mainSideBarContent}</MainMenu>;
};

export default DashboardSideBar;
