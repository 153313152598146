import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import Icons from "icons/sidebar";

import { styled } from "@mui/material";
import useAuth from "hooks/useAuth";

const BottomNavigationStyle = styled(BottomNavigation)(({ theme }) => ({
  width: "100%",
  position: "fixed",
  bottom: 0,
  zIndex: 1000,
  backgroundColor: "white",
}));

export default function BottomNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const { user } = useAuth();

  React.useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setValue(0);
        break;
      case "/dashboard/appointments":
        setValue(1);
        break;
      case "/dashboard/history":
        setValue(2);
        break;
      case "/dashboard/services":
        setValue(3);
        break;
      case "/dashboard/users":
        setValue(4);
        break;
    }
  }, [location.pathname]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        navigate("/dashboard");
        break;
      case 1:
        navigate("/dashboard/appointments");
        break;
      case 2:
        navigate("/dashboard/history");
        break;
      case 3:
        navigate("/dashboard/services");
        break;
      case 4:
        navigate("/dashboard/users");
        break;
      default:
        break;
    }
  };

  return (
    <BottomNavigationStyle value={value} onChange={handleChange} showLabels>
      {user?.kind === "owner" && (
        <BottomNavigationAction
          label="Dashboard"
          icon={<Icons.DashboardIcon />}
          sx={{
            "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
              color: value === 0 ? "primary" : "#94a9cd", fontSize: 30
            },
            "& .MuiBottomNavigationAction-root, .Mui-selected, span": {
              color: value === 0 ? "primary" : "#94a9cd", fontSize: 9
            },
          }}
        />
      )}
      <BottomNavigationAction
        label="Agenda"
        icon={<Icons.EventAvailableIcon />}
        sx={{
          "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
            color: value === 1 ? "primary" : "#94a9cd", fontSize: 30
          },
          "& .MuiBottomNavigationAction-root, .Mui-selected, span": {
            color: value === 1 ? "primary" : "#94a9cd", fontSize: 9
          },
        }}
      />

      {(user?.kind === "owner" || user?.kind === "employee") && (
        <BottomNavigationAction
          label="Hitórico"
          icon={<Icons.ListIcon />}
          sx={{
            "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
              color: value === 2 ? "primary" : "#94a9cd", fontSize: 30
            },
            "& .MuiBottomNavigationAction-root, .Mui-selected, span": {
              color: value === 2 ? "primary" : "#94a9cd", fontSize: 9
            },
          }}
        />
      )}

      {user?.kind === "owner" && (
        <BottomNavigationAction
          label="Serviços"
          icon={<Icons.BusinessCenterIcon />}
          sx={{
            "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
              color: value === 3 ? "primary" : "#94a9cd", fontSize: 30
            },
            "& .MuiBottomNavigationAction-root, .Mui-selected, span": {
              color: value === 3 ? "primary" : "#94a9cd", fontSize: 9
            },
          }}
        />
      )}

      {user?.kind === "owner" && (
        <BottomNavigationAction
          label="Profissionais"
          icon={<Icons.PeopleAltIcon />}
          sx={{
            "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
              color: value === 4 ? "primary" : "#94a9cd", fontSize: 30
            },
            "& .MuiBottomNavigationAction-root, .Mui-selected, span": {
              color: value === 4 ? "primary" : "#94a9cd", fontSize: 9
            },
          }}
        />
      )}
    </BottomNavigationStyle>
  );
}
