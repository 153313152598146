import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import Form from "components/crud/form";
import LoadingScreen from "components/LoadingScreen";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const ResetPassword = Loadable(lazy(() => import("./pages/authentication/ResetPassword")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

// Outhes pages
const CompanyShowAppointment = Loadable(lazy(() => import("./pages/CompanyShowAppointment")));
const CompanyService = Loadable(lazy(() => import("./pages/CompanyService")));
const CompanyEmployee = Loadable(lazy(() => import("./pages/CompanyEmployee")));
const CompanyFinishedAppointment = Loadable(lazy(() => import("./pages/CompanyFinishedAppointment")));
const Plans = Loadable(lazy(() => import("./pages/Plans")));

// Dashboard pages
const Dashboard = Loadable(lazy(() => import("./pages/dashboards/Dashboard")));
const Services = Loadable(lazy(() => import("./pages/dashboards/Services")));
const History = Loadable(lazy(() => import("./pages/dashboards/History")));
const Employees = Loadable(lazy(() => import("./pages/dashboards/Employees")));
const Appointments = Loadable(lazy(() => import("./pages/dashboards/Appointments")));

// user profile
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "reset-password",
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "planos",
    element: (
      <GuestGuard>
        <Plans />
      </GuestGuard>
    ),
  },
  {
    path: "agendar/:slug",
    element: (
      <GuestGuard>
        <CompanyService />
      </GuestGuard>
    ),
  },
  {
    path: "agenda/:uuid",
    element: (
      <GuestGuard>
        <CompanyShowAppointment/>
      </GuestGuard>
    ),
  },
  {
    path: "agendar/:slug/services/:service_id",
    element: (
      <GuestGuard>
        <CompanyEmployee />
      </GuestGuard>
    ),
  },
  {
    path: "agendar/:slug/services/:service_id/users/:user_id",
    element: (
      <GuestGuard>
        <CompanyFinishedAppointment />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "appointments",
        element: <Appointments />,
      },
      {
        path: "history",
        element: <History />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "users",
        element: <Employees />,
      },
      {
        path: ":routeDefault/list",
        element: <Services />,
      },
      {
        path: ":routeDefault/:id",
        element: <Form />,
      },
      {
        path: ":routeDefault/:create",
        element: <Form />,
      },
      {
        path: ":routeDefault/:id/:view",
        element: <Form />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },
    ],
  },
  {
    path: "agendar",
    element: (
        <Error />
    ),
    children: [
      {
        path: "",
        element: <Error />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },

    ],
  },
  {
    path: "*",
    element: <Error />
  },
];

export default routes;
