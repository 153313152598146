import {
  GridView as UserGridIcon,
  Login as LoginIcon,
  PersonAdd as AddUserIcon,
} from "@mui/icons-material";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import DashboardIcon from "./Dashboard";
import SessionsIcon from "./Sessions";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";
import FlagIcon from '@mui/icons-material/Flag';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ListIcon from '@mui/icons-material/List';


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginIcon,
  AddUserIcon,
  SessionsIcon,
  UserGridIcon,
  DashboardIcon,
  UserProfileIcon,
  UserManagementIcon,
  SportsSoccerIcon,
  FlagIcon,
  SearchIcon,
  AddIcon,
  CheckIcon,
  FilterAltIcon,
  BusinessCenterIcon,
  PeopleAltIcon,
  EventAvailableIcon,
  ListIcon
};
